<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="활동월"
            name="measureYear"
            type="month"
            :range="true"
            default="today"
            v-model="searchParam.measureYear"
          />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="배출량 실적관리"
          tableId="processManual"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="true"
          :usePaging="false"
          :expandAll="true"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="linkClick" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '사업장',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: '활동월',
            align: 'center',
            style: 'width: 120px',
            sortable: true,
            fix: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '배출활동',
            align: 'center',
            sortable: true,
            fix: true,
          },
          {
            name: 'col6',
            field: 'col6',
            label: '배출시설',
            align: 'center',
            type: 'link',
            sortable: true,
            fix: true,
          },
          {
            name: 'col0',
            field: 'col0',
            label: '연료별 배출량',
            align: 'center',
            child: [
              {
                name: 'col7',
                field: 'col7',
                label: '연료명',
                align: 'center',
                sortable: true,
              },
              {
                name: 'col8',
                field: 'col8',
                label: 'CO2',
                align: 'center',
                type: 'cost',
                sortable: true,
              },
              {
                name: 'col9',
                field: 'col9',
                label: 'CH4',
                align: 'center',
                type: 'cost',
                sortable: true,
              },
              {
                name: 'col10',
                field: 'col10',
                label: 'N2O',
                align: 'center',
                type: 'cost',
                sortable: true,
              },
            ],
          },
          {
            name: 'col25',
            field: 'col25',
            label: '연료별 배출계수',
            child: [
              {
                name: 'col26',
                field: 'col26',
                label: 'TCO2/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
              {
                name: 'col27',
                field: 'col27',
                label: 'kgCH4/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
              {
                name: 'col28',
                field: 'col28',
                label: 'kgN20/TJ,MWH',
                type: 'cost',
                sortable: true,
                style: 'width: 120px',
              },
            ],
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        col1: null,
        col2: null,
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: false,
        width: '100%',
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          col1: '사업장1', 
          col2: '2022-11',
          col5: '기체연료연소', 
          col6: '공정연소시설 #021', 
          col7: '나프타', 
          col8: '123', 
          col9: '0.8', 
          col10: '0.12', 
          col11: '2022',
          col26: '0.0021',
          col27: '0.0009',
          col28: '0.0150',
        },
        {
          col1: '사업장1', 
          col2: '2022-11',
          col5: '기체연료연소', 
          col6: '공정연소시설 #021', 
          col7: '중류', 
          col8: '21', 
          col9: '0.9', 
          col10: '0.11', 
          col11: '2022',
          col26: '0.0094',
          col27: '0.0071',
          col28: '0.0050',
        },
        {
          col1: '사업장1', 
          col2: '2022-11',
          col5: '기체연료연소', 
          col6: '공정연소시설 #033', 
          col7: 'LNG', 
          col8: '120', 
          col9: '0.35', 
          col10: '0.7', 
          col11: '2022',
          col26: '0.0042',
          col27: '0.0017',
          col28: '0.0190',
        },
      ];
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./emissionsDetail.vue"}`);
      this.popupOptions.title = '배출활동/배출시설별 배출량 상세';
      this.popupOptions.param = {
        psiDocumentId: result,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
